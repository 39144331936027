module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://fearofmissingoutproject.com/","i18nextOptions":{"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":["en,es"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fear Of Missing Out","short_name":"FOMO","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"static/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7f73ac80fa26dd425771423e8e868a47"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
